import React, { Component } from "react";
// import qrborder from "../assets/images/icons/qrborder.svg";
import { history } from "../_helpers";
import { pendingpaymentService } from "../_services";
import scan from "../_assets/images/icons/scan.png";
import i18next from "i18next";
import { ClipLoader } from "react-spinners";
import ModelPopup from "../components/Model-Popup/Popup";
import FooterNav from "../components/FooterNav/FooterNav";
import SplashScreenImg from "../_assets/images/splashscreen/loader.gif";
import { Link } from "react-router-dom";
import { config } from "../_config";
import ConfirmPopup from '../components/ConfirmPopup/ConfirmPopup';
import { isBiometricSupported } from "../_helpers/common";
import DiscountOffer from "../components/DiscountOffer/DiscountOffer"
import { cardService } from "../_services/card.service";
import { utilService } from "../_services/util.service";
import { userService } from "../_services/user.service";
import { isIOS, osVersion } from "react-device-detect";
import CoolerPopup from "../components/Model-Popup/Popup";
import { Trans } from "react-i18next";
import { getAppId } from "../_helpers/getAppId";
import { Modal, Button as Btn } from 'antd';
import Button from "../components/button";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData, encryptData } from "../_helpers/encryptData";
import { getDecouplePromotion } from "../_services/reward.service";
import DecouplePromo from "../components/DecouplePromo/DecouplePromo";
import cokebanner from "../_assets/images/splashscreen/cokebanner.svg"
import RewardPoints from "../components/RewardPoints/RewardPoints";
const ApplicationTypeId = getAppId();
export default class Scanbutton extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | Scanbutton`;
    CapturePage({hitType:"pageview",page:"/Scanbutton"})
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    this.state = {
      isLoading:true,
      isPendingAvailable:false,
      isOffline:false,
      PurchaseQty:0,
      BuyQty:0,
      isBiometricSupported:'',
      bioMetricPopup: false,
      cardList:[],
      ShowPopUp:false,
      popupmsg:i18next.t(config.applicationTypeId==19?"Payment method is not added, Please link your Paytm Wallet to open the cooler":'PlzAddCard'),
      lastSurveyDate:'',
      surveyStatus:false,
      purchaseNumber:'',
      firstPurchaseDone:false,
      ignorePayment:decryptData(localStorage.getItem('ignorePayment')),
      promotionOffers:[],
      termsInformPopUp: user.isTermAndCondition
    }
    
    
    // let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authUser = user.authToken;
    let Iduser = user.userId;    
    cardService.getCardList()
    .then((result)=>{         
      if(result.success){
        this.setState({
          cardList:result.cardList
        })      
        if(config.applicationTypeId==19){
          sessionStorage.setItem('paymentFlow',result.paymentFlow)
          sessionStorage.setItem('paymentGateway',result.paymentGateway)
        }
        if(result.cardList.length==0){
          // localStorage.setItem('cardlist','true')
        }               
      }
      else{
        if(result.code==401){
          history.push("/logout")
        }
      }
      this.setState({
        isLoading:false
      })
    })
    .catch((err)=>{
      console.log(err)
      this.setState({
        isLoading:false
      })
    })

    this.openScanner = this.openScanner.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleOKEventClick = this.handleOKEventClick.bind(this);
    this.closingisOfflinePopup = this.closingisOfflinePopup.bind(this);
    this.handleCloseShowPopUp = this.handleCloseShowPopUp.bind(this);
    this.ManageSaferPay = this.ManageSaferPay.bind(this);
    this.handleCompletePayment = this.handleCompletePayment.bind(this);
    this.checkSupport();

    this.checkSurveyStatus();
    this.getPurchaseHistory();
    let qrcode=localStorage.getItem('qrcode');
    if(qrcode){
      this.getPromoDetails();
    }
    this.sendMetricsTracking();
  }

  sendMetricsTracking = () => {
    utilService.metricsTracking("ScanPage");
  }

  updateTerms = () => {
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    user.isTermAndCondition = false;
    userService.update(user);
    this.setState({
      termsInformPopUp: false
    })
  }

  getPromoDetails=async()=>{
    let that =this
    try {
      getDecouplePromotion("home").then((data)=>{
        if(data.success && data.code==5014){
        let homePD = data.data.home.sort((a,b)=>a.bannerPositionInScreen-b.bannerPositionInScreen)
        this.setState(prevstate=>({
          ...prevstate,
          promotionOffers:homePD
        }))
        }
      })
      .catch((err)=>{
        
      })
    } catch (error) {
      console.log(error)
    }
  }
  checkSupport=async()=>{
    let check = await isBiometricSupported();
    const isBiometricEnabled = localStorage.getItem("isBiometricEnabled")
    let currentOSVer = osVersion.substring(0,4);
    this.setState({
      isBiometricSupported:check,
      bioMetricPopup:(isIOS && (currentOSVer=="15.5" || currentOSVer=="15.6"))?false:(!isBiometricEnabled && check)?true:false
    })
  }

  checkSurveyStatus=()=>{
    let that = this;
    that.setState({
      isLoading:true
    })
    const applicationTypeId=config.applicationTypeId;
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    let sessionUId = sessionStorage.getItem("sessionId");
    let data = {applicationTypeId,authToken,sessionUId}
    fetch(config.envUrl + "VendingAppApi/getSurveyStatus3", {
    method: "POST",
    body: new URLSearchParams(data),
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
    })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
        
    })
    .then(function (body) {
        that.setState({
          isLoading:false,
          surveyStatus:body.surveyStatus3,
          firstPurchaseDone:body.firstPurchaseDone
        })
    })
  }

  getPurchaseHistory=()=>{
    let that = this;
    that.setState({
      isLoading:true
    })
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    const applicationTypeId = config.applicationTypeId;
    let userId = user.userId;
    let sessionUId = sessionStorage.getItem("sessionId");
    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,applicationTypeId, userId, sessionUId})
    }

    fetch(`${config.envUrl}VendingAppApi/fetchPendingPaymentV2`, RequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((data)=>{
      that.setState({
        isLoading:false,
        purchaseNumber:data.code==5011?data.data.purchaseNumber:''
      })
    })
    .catch((err)=>{
      that.setState({
        isLoading:false
      })
    })
  }

  openScanner() {
    if(navigator.onLine){
      history.push({
        pathname: "/Scanner"
      });
      // if(this.state.cardList.length>0){
      //   history.push({
      //     pathname: "/Scanner"
      //   });
      // }
      // else{
      //   this.setState({
      //     ShowPopUp:true,
      //     popupmsg:i18next.t(config.applicationTypeId==19?"Payment method is not added, Please link your Paytm Wallet to open the cooler":'PlzAddCard')
      //   })
      // }
    }
    else{ 
      this.setState({
        isOffline:true
      })     
      
    }
  }

  closingisOfflinePopup(){
    this.setState({
      isOffline:false
    })
  }
  handleCloseShowPopUp(){
    if(config.paymentGateway==1 || config.paymentGateway==3 || config.paymentGateway==9){
      sessionStorage.setItem('scanbutton','true');
      history.push("/AddCard");
    }
    else if(config.paymentGateway==2){
      this.ManageSaferPay();
    }
    else if(config.paymentGateway==4){
      history.push({pathname:'/link-with-paytm',state:"ScanButton"});
    }
  }
  ManageSaferPay(){
    let that = this;
    this.setState({
      isLoading:true,
      ShowPopUp:false
    })
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    const applicationTypeId = config.applicationTypeId;
    const returnSuccessUrl = config.baseURL+'/AddCard';
    let sessionUId = sessionStorage.getItem('sessionId');
    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,applicationTypeId, returnSuccessUrl,sessionUId})
    }

    fetch(`${config.envUrl}VendingAppApi/addPayment`, RequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        if(result.success && result.returnCode==5041){
            sessionStorage.setItem('token',result.token);
            sessionStorage.setItem('scanbutton','true');
            window.location = result.redirectURL;
        }
        else{
            if(result.code==401){
                that.setState({
                    isLoading:false,
                    errorCode:result.code,
                    imagetype:'error',
                    ShowPopUp:true,
                    popupmsg:result.message
                })
            }
            else{
                that.setState({
                    isLoading:false,
                    errorCode:result.code,
                    imagetype:'error',
                    ShowPopUp:true,
                    popupmsg:result.message
                })
            }
        }
    }).catch((error) =>{
        that.setState({
            isLoading:false,
            imagetype:'error',
            ShowPopUp:true,
            popupmsg:i18next.t('CommonError')
        })
    });
  }
  handleCancelClick(){
    localStorage.setItem("isBiometricEnabled","false")
    this.setState({
      bioMetricPopup:false
    })
  }
  handleOKEventClick(){
    localStorage.setItem("isBiometricEnabled","false")
    history.push("/Setting")
  }

  handleCompletePayment(){
    history.push({
      pathname:"/PayOutstandingbalance/"+this.state.purchaseNumber,
      state:''
    })
  }
  ignorePayment=()=>{
    localStorage.setItem('ignorePayment',decryptData('true'));
    this.setState({
      ignorePayment:'true'
    })
  }

  getUpdatedOffer = () => {
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    const applicationTypeId = config.applicationTypeId;
    let macAddress = decryptData(localStorage.getItem('qrcode'));
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams({
        authToken,
        applicationTypeId,
        macAddress
      })
    };
    fetch(
      `${config.envUrl}VendingAppApi/getConsumerOfferDetails`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (body) {
        localStorage.setItem('reward', encryptData(JSON.stringify(body)))
      })
  }

  render() {
    let user = JSON.parse(decryptData(localStorage.getItem('user')));
    
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    // let isB0G1Fenabled = localStorage.getItem('isB0G1Fenabled');
    let reward = localStorage.getItem('reward') ? JSON.parse(decryptData(localStorage.getItem('reward'))) : {};
    var {separateOfferCoupon:separateOfferCoupon=[], OfferCoupon: OfferCoupon=[]} = reward;
    var isB10G1Fenabled = OfferCoupon.filter((item)=>item.OfferTypeId==1);
    // let count = [1,2,3,4,5,6,7,8,9,10]
    const isBiometricEnabled = localStorage.getItem("isBiometricEnabled")
    const {firstPurchaseDone, surveyStatus, promotionOffers} = this.state;
    // let days=0;
    // if(surveyStatus && lastSurveyDate!=""){
    //   const date1 = new Date(lastSurveyDate);
    //   const date2 = new Date();
    //   const diffTime = Math.abs(date2 - date1);
    //   days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // }
    const surveylink = `/consumer-survey?applicationtypeid=${config.applicationTypeId}&authtoken=${user.authToken}&isB10G1Enabled=${isB10G1Fenabled.length>0?1:0}&languageCode=en`;
    const ignorePayment = decryptData(localStorage.getItem('ignorePayment'))
    const qr = decryptData(localStorage.getItem("qrcode"));
    const singaporeStyle={
      fontSize:'16px',
      width:'50%'
    }
    let rewardData = JSON.parse(decryptData(localStorage.getItem('isRewardAvailable')));
    return (
      <div className="main-wrapper">
      <div className="container p-0">
        <div className="row justify-content-center m-0">
          <div className="col-lg-4 col-md-6  d-flex flex-column  align-items-center justify-content-center p-0">
            <div className="content p-0" style={{overflowY:'scroll',height:'calc(100vh - 80px)'}}>
            {/* {
          ((ApplicationTypeId === 9 || ApplicationTypeId === 13 || ApplicationTypeId === 14 || ApplicationTypeId === 15 || ApplicationTypeId === 20) && user.isLatamShowWebAppBanner && separateOfferCoupon.length>0 && qr!='') &&
          <div className="text-center promo-banner" style={{ background: `url(${config.promoBannerFlag})` }}>
            <div className="promo-banner-content">
              <p style={ApplicationTypeId === 20?singaporeStyle:null}>
                <Trans i18nKey="Enjoy your first beverage from Coke&GO on us!" components={[<br />]}>
                </Trans></p>
              <Link className="details-btn" to='/Rewards'>
                {i18next.t('See Details')}
              </Link>
            </div>
          </div>
        } */}
        <div className="profile-name py-2 fix-header">
                <h4 className="mb-0">{i18next.t("Hello")}{config.applicationTypeId==12 ? ' ' : ', '}{user.firstName}</h4>
                <div className="scan-btn my-2 d-flex" onClick={this.openScanner}>
                  <img src={scan} height={30} /> 
                  <h5 className="mt-0">{i18next.t('ScanCoolerQR')}</h5> 
                </div>
              </div>
        <div className="px-2">
              
              <div className="main-content pb-5" style={{overflowY:'unset'}}>
                
               
                <div className="rewards-list">
                  { 
                    ((rewardData && rewardData.length>0 && qr) || separateOfferCoupon.length>0) &&
                    <h4 className="title">{i18next.t("Your rewards")}</h4>
                  }
                  { separateOfferCoupon.length>0 &&
                  <div className="reward-banner mb-4">
                    <div className="row">
                      <div className="col-7 pl-4">
                        {config.applicationTypeId==20 ? <h5>{i18next.t("Free Drink is applicable per user+card option and not per user registration basis")}</h5> : <h5>{i18next.t("CHEERS! Enjoy your first beverage on us.")}</h5>}
                      </div>
                      <div className="col-5">
                        <img src={cokebanner} width={'100%'}/>
                      </div>
                    </div>
                    <div className="row px-3">
                      <div className="col-7">
                        <p className="redeem-now" onClick={this.openScanner}>{i18next.t('Redeem Now')}</p>
                      </div>
                      <div className="col-5 text-right">
                        <Link to={config.termsOfUseAndSale}><p>{i18next.t('Terms')}</p></Link>
                      </div>
                    </div>
                  </div>
                  }
                  <RewardPoints />
                </div>
                
                <div className="text-center">
                  <img src={SplashScreenImg} className="w-100 mt-3" />
                </div>
                {
                  (config.isSurveyEnabled && surveyStatus && firstPurchaseDone) &&
                  <div className="survey-banner mt-2">
                    <h4 className="text-uppercase mb-0">{i18next.t("How's it")}</h4>
                    <h3 className="coke text-uppercase">{i18next.t('Coke&Going')}</h3>
                    <p style={{fontSize:'12px'}}>{i18next.t("Answer a few questions about your Coke&Go purchase experience")}</p>
                    <div className="survey_btn">
                      <Link to={surveylink}><span className="text-uppercase">{i18next.t("Take survey")}</span></Link>
                    </div>
                  </div>
                } 
                 {
                  promotionOffers.length>0 && 
                  <div className="mt-3" >
                    {
                      promotionOffers.map((item)=>{
                        return <>
                          <DecouplePromo {...item} backLink="/scanButton" key={`key_${Math.random()+10000}`} />
                        </>
                      })
                    }
                  </div>
                }
              </div>
            </div>
              <FooterNav isPending={this.state.isPendingAvailable} isMenuOpen={this.props.location.state=='back'?true:false} />
            </div>
            {
              this.state.isLoading &&
              <div className="spinner">
                <div style={style}>
                  <ClipLoader size={50} color={"#000000"} />
                </div>
              </div>
            }

            {this.state.isOffline ? (
              <ModelPopup
                openPopup={this.state.isOffline}
                closePopup={this.closingisOfflinePopup}
                message={i18next.t("ConnectionIssue")}
                imagetype={"error"}
              />
            ) : null}
            {
              this.state.ShowPopUp && 
              <ModelPopup
                  openPopup={this.state.ShowPopUp}
                  closePopup={this.handleCloseShowPopUp}
                  message={i18next.t(this.state.popupmsg)}
                  imagetype="error"
                      
              />
            }
            {
              (!isBiometricEnabled && this.state.isBiometricSupported) &&
            
              <ConfirmPopup
                imagetype="Error"
                color="Red"
                openPopup={this.state.bioMetricPopup}
                message={i18next.t("Would you like to enable Bio-metric authentication login?")}
                CancelText={i18next.t("No")}
                EventText={i18next.t("Yes")}
                handleEventClick={this.handleOKEventClick}
                handleCancelClick={this.handleCancelClick}
              />
            }
            {
              (config.showPendingPopup && this.state.purchaseNumber!='' && this.state.ignorePayment!='true') &&
              <CoolerPopup
                openPopup={true}
                imagetype="error"
                title={i18next.t("Payment pending!")}
                description={i18next.t("Ops, it looks like you have an outstanding balance. Please complete your previous payment before proceeding")}
                btnText={i18next.t("Complete payment")}
                ignorePayment={this.ignorePayment}
                clickHandle={this.handleCompletePayment}
                fullWithButton={true}   
                isPaymentPending={config.showPendingPopup}
              />
            }

              {
                (this.state.termsInformPopUp && config.applicationTypeId == 20)
                &&
                <Modal title={false} closable={false} centered={true} visible={true} footer={false}>
                  <div className="text-center">
                    <p className="popup-msg ">Please note our <b><Link className="text-black text-underline" to={config.termsOfUseAndSale}>Terms {'&'} Conditions</Link></b> have been updated. Review and continue making purchase</p>
                      <button 
                        className='btn bg-white btn-with-border' 
                        style={{width:'160px'}}
                        onClick={this.updateTerms}
                      >
                      OK
                      </button>
                  </div>
                </Modal>
              }
          </div>
        </div>
      </div>
      </div>
    );
  }
}
