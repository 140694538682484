import {
    cdsConfig,
    config
} from '../_config/index';
import { decryptData, encryptData } from '../_helpers/encryptData';
import { v4 as uuidv4 } from "uuid";
export const userService = {
    login,
    register,
    update,
    logout,
    getPolicy,
    checkMaintenance
};

function login(email, password, rememberme, thirdPartyQR) {
    const applicationTypeId = config.applicationTypeId;
    let isBiometricEnabled = localStorage.getItem("isBiometricEnabled");
    let emailId = decryptData(localStorage.getItem("username"));
    let bodyData;
    let apiURL;
    let sessionUId = sessionStorage.getItem("sessionId");
    if(!sessionUId){
        sessionUId = uuidv4();
        sessionStorage.setItem('sessionId',sessionUId);
    }
    // console.log({isBiometricEnabled},{password})
    if(isBiometricEnabled==="true" && password===""){
        let deviceId = email;
        apiURL = `${config.envUrl}VendingAppApi/getConsumerDeviceRegistration`
        bodyData = new URLSearchParams({
            deviceId,
            emailId,
            applicationTypeId
        })
    }
    else{
        apiURL = `${config.envUrl}VendingAppApi/loginV2`
        bodyData = new URLSearchParams({
            email,
            password,
            rememberme,
            applicationTypeId,
            sessionUId
        })
    }
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: bodyData
    };

    return fetch(apiURL, requestOptions)
        .then(handleResponse)
        .catch(err => {
            return Promise.reject(err);
        })
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let hrs = new Date();
            // if(rememberme){
            //     if(config.applicationTypeId==20){
            //         hrs.setMinutes(hrs.getMinutes() + config.sessionExpiryTime); // 1 month
            //     }
            //     else{
            //         hrs.setHours(hrs.getHours() + 24*30); // 1 month
            //     }
            // }
            // else{
            //     if(config.applicationTypeId==20){
            //         hrs.setMinutes(hrs.getMinutes() + config.sessionExpiryTime); // 1 day
            //     }
            //     else{
            //         hrs.setHours(hrs.getHours() + 24); // 1 day
            //     }
            // }
            hrs.setMinutes(hrs.getMinutes() + config.sessionExpiryTime);
            user.expiry = hrs;
            localStorage.setItem('user', encryptData(JSON.stringify(user)));
            localStorage.setItem('username', encryptData(user.primaryEmail));
            localStorage.setItem('rememberme',encryptData(JSON.stringify(rememberme)));
            localStorage.setItem('rewardPoint', encryptData(JSON.stringify(user.rewardPoint)));
            localStorage.setItem('isEncrypted','true')
            return user;
        });
}

function update(userUpdateData) {
    let formData = new FormData();
    let authToken = userUpdateData.authToken;
    let FirstName = userUpdateData.firstName;
    let LastName = userUpdateData.lastName;
    let contactNumber = userUpdateData.mobileNumber;
    // let file = userUpdateData.file[0];
    let profileImageURL = userUpdateData.profileImageURL;
    const applicationTypeId = config.applicationTypeId;
    if(applicationTypeId == 20){
        formData.append('isTermAndCondition', userUpdateData.isTermAndCondition)
    }
    let sessionUId = sessionStorage.getItem("sessionId");
    formData.append('authToken', authToken)
    formData.append('FirstName', FirstName)
    formData.append('LastName', LastName)
    formData.append('contactNumber', contactNumber)
    // formData.append('file', file)
    formData.append('applicationTypeId', applicationTypeId)
    formData.append('sessionUId',sessionUId);

    const requestOptions = {
        method: 'POST',
        body: formData
    };

    return fetch(`${config.envUrl}VendingAppApi/updateProfile`, requestOptions)
        .then(handleResponse)
        .catch(err => {
            console.log('update error',err);
            return Promise.reject(err)
        })
        .then(user => {
            if(user.success){
                var olduserdetails = JSON.parse(decryptData(localStorage.getItem('user')));
                olduserdetails.firstName = FirstName
                olduserdetails.lastName = LastName
                olduserdetails.mobileNumber = contactNumber
                olduserdetails.smsMarketing = contactNumber?olduserdetails.smsMarketing:false
                if(applicationTypeId == 20){
                    olduserdetails.isTermAndCondition = userUpdateData.isTermAndCondition
                }
                localStorage.setItem('user', encryptData(JSON.stringify(olduserdetails)));
            }
            return user;
        });
}

function register(signupData) {
    const Primaryemail = signupData.Primaryemail;
    const firstName = signupData.firstName;
    const lastName = signupData.lastName;
    const mobileNumber = signupData.mobileNumber;
    const birthDate = signupData.birthDate.split("/").reverse().join("-");
    const password = signupData.password;
    const confirmpassword = signupData.confirmpassword;
    const mailMarketing = signupData.mailMarketing;
    const smsMarketing = signupData.smsMarketing;
    const applicationTypeId = config.applicationTypeId;
    const userTypeId = (config.applicationTypeId==9)?2:1;
    const CPF = signupData.cpf;
    const platform = "webapp";
    let sessionUId = sessionStorage.getItem("sessionId");
    if (!sessionUId) {
        sessionUId = uuidv4();
        sessionStorage.setItem('sessionId', sessionUId);
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            Primaryemail,
            firstName,
            lastName,
            mobileNumber,
            birthDate,
            password,
            confirmpassword,
            applicationTypeId,
            userTypeId,
            mailMarketing,
            smsMarketing,
            CPF,
            platform,
            sessionUId
        })
    };
    if(config.applicationTypeId==9){
        return fetch(`${config.envUrl}VendingAppApi/cokenowBrazilUserRegistrationV2`, requestOptions)
        .then(handleResponse)
        .catch(err => {
            return Promise.reject(err)
        })
        .then(user => {
            return user;
        });
    }
    else{
        return fetch(`${config.envUrl}VendingAppApi/userRegistrationV2`, requestOptions)
        .then(handleResponse)
        .catch(err => {
            return Promise.reject(err)
        })
        .then(user => {
            return user;
        });
    }
    
}

async function logout() {
    try {
        let sessionUId = sessionStorage.getItem("sessionId");
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let authToken = user.authToken;
        let applicationTypeId=config.applicationTypeId
        // https://portal-qa.instagng.com/VendingAppApi/logout
        const requestOptions={
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                applicationTypeId,
                authToken,
                sessionUId
            })
        };
        fetch(`${config.envUrl}VendingAppApi/logout`, requestOptions)
    } catch (error) {
        
    }
    let isCDSEnabled = localStorage.getItem("cdsEnabled")
    let lang = localStorage.getItem('i18nextLng');
    let isOldUser = localStorage.getItem('isOldUser');
    let isBiometricEnabled = localStorage.getItem('isBiometricEnabled');
    let username = localStorage.getItem('username');
    let selectedLanguage = localStorage.selectedLanguage
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('i18nextLng',lang);
    localStorage.setItem('isOldUser',isOldUser);
    if(config.languageList.indexOf(selectedLanguage)>-1){
        localStorage.setItem("selectedLanguage",selectedLanguage);
    }
    if(isBiometricEnabled){
        localStorage.setItem('isBiometricEnabled',isBiometricEnabled);
        localStorage.setItem('username',username);
    }
    if(isCDSEnabled=="true"){
        const sdk = await window.tcccLogin.LoginSdk({
            clientID: cdsConfig.clientID,
            stringRedirectUrl:"/",
            tokenExchangeUrl:cdsConfig.tokenExchangeUrl
        });
        await sdk.isSDKReady();
        window.sdk = sdk;
        await window.sdk.doLogOut();
    }
    // remove user from local storage to log user out
    // localStorage.removeItem('user');
    // localStorage.removeItem('qrcode');
    // localStorage.removeItem('deviceData');
    // localStorage.removeItem('coolerId');
    // localStorage.removeItem('showtutorial');
    // localStorage.removeItem('paymentcompleted');
    // localStorage.removeItem('rewardPoint');
    // localStorage.removeItem('rememberme');
    // localStorage.removeItem('preauth');

}

function getPolicy(Request){
    // let user = JSON.parse(localStorage.getItem("user"));
    // let authToken = user.authToken;
    const applicationTypeId = config.applicationTypeId;
    const documentType= Request.documentType
    const languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
    let sessionUId = sessionStorage.getItem("sessionId");
    return fetch(config.envUrl + "VendingAppApi/getDocumentContent", {
        method: "POST",
        body: new URLSearchParams({
            applicationTypeId,
            documentType,
            languageCode,
            sessionUId
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        return data;    
    })
    .catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}
function checkMaintenance(Request){
    // let user = JSON.parse(localStorage.getItem("user"));
    // let authToken = user.authToken;
    if(window.location.hostname=='localhost'){
        return false
    }
    const applicationTypeId = config.applicationTypeId;
    return fetch(config.envUrl + "CheckOnline.html", {
        method: "GET",
    })
    .then((data) => {
        if(data.status=="404"){
            return false;
        }
        else{
            return true;
        } 
    })
    .catch(function (error) {
        console.log(error)
        return false
    });
    // return false
}

function handleResponse(response) { // for every request handling
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (data.success) {
            return data;
        } else {
            const error = (data && data.code) || response.statusText;
            return Promise.reject(error);
        }

    });
}