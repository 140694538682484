import { getAppId } from "../_helpers/getAppId";
import promoBannerCoke from '../_assets/images/splashscreen/PromoBannerCoke.png';
import promoBannerFanta from '../_assets/images/splashscreen/PromoBannerFanta.png';
import PromoBannerSprite from '../_assets/images/splashscreen/PromoBannerSprite.png';
const env = "Prod"; // QA or Prod or Pre-Prod
const ApplicationTypeId = getAppId();

// Application Type Id :
// CokeNow BR : 9
// CCH : 10
// CokeNow Poland: 11
// CokeNow Austria: 12
// CokeNow Chile: 13
// CokeNow Mexico: 14
// CokeNow Peru: 15
// CokeNow Colambia: 16
// CokeNow South Africa: 17
// CokeNow India: 19
const PaymentGatewayList = {
    1:"cielo",
    2:"saferpay",
    3:"dlocal",
    4:"paytm",
    5:"globalpay",
    6:"auresys",
    7:"payu",
    8:"elavon",
    9:"M2M"
}
var config = {
    sessionExpiryTime:30,
    baseURL: window.location.origin,
    applicationTypeId: ApplicationTypeId,    
    GoogleClientId: "",
    FacebookAppId: "682396719481850",
    AppleClientId: "com.instagng.sociallogintest",
    currencyCode: "",
    currencySymbol: "",
    enableCardScan:true,
    privacyPolciy: process.env.PUBLIC_URL + "/policy/PrivacyPolicy",
    termsOfUseAndSale: process.env.PUBLIC_URL + "/policy/TermsOfSale",
    termsOfUse: process.env.PUBLIC_URL + "/policy/TermsOfUse",
    marketingTerms: process.env.PUBLIC_URL + "/policy/Marketing",
    contactUs:process.env.PUBLIC_URL + "/policy/ContactUs",
    faq:process.env.PUBLIC_URL + "/policy/FAQDocuments",
    bioMetric:{
        baseURL:"",
        clientID:""
    },
    minAge: 14,
    maxAge: 120,
    supportEmail:"cic@coca-cola.com.my",
    postSaleAPI:false,
    iOSVersionForDisableBio:['15.5','15.6'],
    isFreeTextForMobile:false,
    isSurveyEnabled:false,
    showPendingPopup:false,
    isMultiLangEnabled:false,
    languageList:[],
    isSaferPayWalletEnabled:false,
    GoogleAnalyticsId:"",
    showSupportButton: false,
    countryDropDown:false,
    auresyssdk: "https://t.2c2p.com/SecurePayment/api/my2c2p.1.7.3.min.js",
    manualCorrectionMail: "cic@coca-cola.com.my",
    isLatamCountry: false,
    elevonSDK:"https://demo.convergepay.com/hosted-payments/PayWithConverge.js",
    elevonURL:"https://test.pgw.suelware.com",
    loyaltyterms: 'html/Ireland.html'
};

var cdsConfig = { 
    clientID: 'cd01b146-09d0-48f1-a00a-0c01d6f98514',
    stringRedirectUrl: '/',
    logoutUrl: '/logout',
    region: "latam_gamma", //
    tokenExchangeUrl: "/token-verification",
    postLogoutRedirectUri: '/socialLogin',
    userData: ['given_name'],
    b2cPolicyName: "B2C_1A_CHANGEPASSWORD_GENERIC",
    b2cTenantId:"2f09d0bb-c222-4396-bc8f-ff45a268d36b",
    b2cPath: "https://gamma-login.latam.coca-cola.com"
}
// QA setup
if(env==="QA"){
    const instaQA_Api = "https://portal-qa.instagng.com/"
    const dasboardQA = "https://dashboard-qa.ebest-iot.com/"
    const socketQA = "https://instagng-ar-maxerience-qa.azurewebsites.net";
     
    config.FacebookAppId = "575511497112466"
    config.envUrl = instaQA_Api
    config.envDashboard = dasboardQA
    config.socketUrl = socketQA
    config.GoogleClientId = "1083860683602-or4eeqljecul7nchqbvg644api0n63g7.apps.googleusercontent.com";
    if(ApplicationTypeId===8){
        config.FacebookAppId = '682396719481850'
        config.countryName = 'United States of America'
        config.defaultLanguage = 'English'
        config.langCode = 'en-US'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "US"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.GoogleAnalyticsId = ""
        config.showSupportButton = true
        config.isMultiLangEnabled = true
        config.languageList = ["English"]
        config.postSaleAPI = false
        config.isSurveyEnabled = true      
    }
    else if(ApplicationTypeId===9){
        config.countryName = 'Brazil'
        config.defaultLanguage = 'Portuguese'
        config.langCode = 'en-US'
        config.paymentGateway = 1 // cielo=1,safer=2,d-local=3
        config.countryName = "BR"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(99) 99999-9999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/AAAA"    
        config.birthDatePlaceHolder = "dd/mm/aaaa"  
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"vCh8bH49RMvNh8vu2nlPU0mfwT7jJIIy1h6Ui6fZScwj2MY290qo68P4d2jtd3GyuHW5THLaKpkP7hO2t1mK7g"
        }
        config.supportEmail = "sacc_faleconosco@coca-cola.com" //cic@coca-cola.com.my"  
        config.postSaleAPI = true
        config.isSurveyEnabled = true
        config.showPendingPopup=true
        config.promoBannerFlag = promoBannerFanta
        config.GoogleAnalyticsId = "G-3CJ1YSK2V7"
        config.showSupportButton = true
        config.manualCorrectionMail = "cic@coca-cola.com.my"
        config.isLatamCountry = true
        cdsConfig.clientID="fee38661-2bc8-4b42-bd19-728107cf0e78"
    }
    else if(ApplicationTypeId===11){
        config.countryName = 'Poland'
        config.defaultLanguage = 'Polish'
        config.langCode = 'en-US'
        config.paymentGateway = 2 // cielo=1,safer=2,d-local=3
        config.countryName = "PD"
        config.minAge = 14
        config.currencyCode = 'PLN'
        config.currencySymbol = 'zł'
        config.mobileFormat = '99999999999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"rv8zSQe4UuktG7MdUplR7GRGKid3XuTb1JAJMuJIjbkR2JGOeEEs9hwSHD0tiev48bvgMwoZhIl6oCzsAlfvzg"
        }  
        config.isFreeTextForMobile=true
        config.isSaferPayWalletEnabled=true
        config.GoogleAnalyticsId = "G-8RSBNC88QW"
        config.supportEmail = "infopl@cchellenic.com"
        config.isSurveyEnabled = true
        config.loyaltyterms = 'html/Poland.html'
    }
    else if(ApplicationTypeId===12){
        config.countryName = 'Austria'
        config.defaultLanguage =  'German'
        config.langCode = 'en-US'
        config.paymentGateway = 2 // cielo=1,safer=2,d-local=3
        config.countryName = "AS"
        config.minAge = 14
        config.currencyCode = 'EUR'
        config.currencySymbol = '€'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"2IMUA9K-ZZVwgUL90Up7MD7NRNO-GwfRzbG_eONpl9vsJWgM-7E5ci6r-S6TKuCbG-mGS6MninCUqkoq5Pu6Ug"
        } 
        config.isFreeTextForMobile=true 
        config.isSaferPayWalletEnabled=true
        config.GoogleAnalyticsId = "G-YC358E3K7J"
        config.supportEmail = "cscbs@cchellenic.com"
        config.isSurveyEnabled = true
        config.loyaltyterms = 'html/Austria.html'
    }
    else if(ApplicationTypeId===13){
        config.countryName = 'Chile'
        config.defaultLanguage =  'Spanish' 
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "CH"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(9) 9999-9999'
        config.mobileLength = 9
        // config.privacyPolciy = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"C3b04v_QdHCH8odTAaCRIKp97Ol9iQzIZtg7xqI0_FrSpzHimiqV9ATK2HOGTZ1MwoCoYwRhkZCiy-b87AXXGw"
        }  
        config.supportEmail = "cic@coca-cola.com.my" 
        config.postSaleAPI = true 
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-RSNBG14PS4"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
        cdsConfig.clientID="cd01b146-09d0-48f1-a00a-0c01d6f98514"
    }
    else if(ApplicationTypeId===14){
        config.countryName = 'Mexico'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'en-US' // for prod es-MX
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "MX"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(999) 9999-999'
        config.mobileLength = 10
        // config.privacyPolciy = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"orXEHoQ-q6lL63X8uvUE6fnrgaHj9QF1m9VFJwnivX5MKy995aPr6m0uU574Ygf92YciXig0gjcIORXuWkoXwg"
        }  
        config.supportEmail = "cic@coca-cola.com.my"  
        config.postSaleAPI = true
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-1J3BG28GXH"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
        cdsConfig.clientID="d4c057e9-21fb-443c-af45-e8952eb2a8ae"
    }
    else if(ApplicationTypeId===15){
        config.countryName = 'Peru'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "PR"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(999) 999-999'
        config.mobileLength = 9
        // config.privacyPolciy = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"HTRAhAIBXKnp8OU3uzdPz4aCsL2BoyQxOkKFDDatf-MtO3FZ2-iWUu8yUzKf7HwDL9fNKNV6K7nfLvcWFV0RCg"
        }  
        config.supportEmail = "contactope@coca-cola.com"
        config.postSaleAPI = true
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-NJKLCY3639"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
        cdsConfig.clientID="e40af4fb-e115-418f-b522-dab77f89c4f7"
    }
    else if(ApplicationTypeId===16){
        config.countryName = 'Colombia'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'es-CL'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "CO"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = 'Col$'
        config.mobileFormat = '+57 9999999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"6ejW5xVRUSIfi5x5EY-PypciiJEAb9gqXJh5rTbohj2WjKda516pTefOWK36ZX33RHlhPCLUBkYKF9iO8t71nw"
        }  
        config.GoogleAnalyticsId = "G-ZHWCL89PJ9"
        config.isMultiLangEnabled = true
        config.languageList = ["Spanish","English"]
        config.showSupportButton = true
        config.postSaleAPI = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
        cdsConfig.clientID="be4c9563-114f-4cd2-876d-15fd6f81ce0e"
    }
    else if(ApplicationTypeId===17){
        config.countryName = 'South Africa'
        config.defaultLanguage = 'English'
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "SA"
        config.minAge = 18
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '99999999999999'
        config.mobileLength = 14
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"3bvT-PufXLrjJ61E0M8F9wMandOPT2ElEOsZtvgZo3giFVfpQDyfI_wgcC2sJAXF4_KmctoIlouUQdx6-ywH9g"
        }  
        config.isFreeTextForMobile=true
        config.GoogleAnalyticsId = "G-6GX3QKBL5F"
    }
    else if(ApplicationTypeId===19){
        config.countryName = 'India'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 4 // cielo=1,safer=2,d-local=3
        config.countryName = "IND"
        config.minAge = 15
        config.currencyCode = 'INR'
        config.currencySymbol = '₹'
        config.mobileFormat = '9999999999'
        config.mobileLength = 10
        config.isSurveyEnabled = true
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"nXrqf8dSxvEjkxRyMzMhy231h9-d8K8grTME3DBSYRU0mI0eRa1NP2ejXWJv2orypYNgGhdtlBa8KZXZ0KVyhw"
        }  
        config.GoogleAnalyticsId = "G-1P68ZFL2BF"
        config.supportEmail="cokeandgosupport@coca-cola.in"
    }
    else if(ApplicationTypeId===20){
        config.countryName = 'Singapore'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 6 // cielo=1,safer=2,d-local=3
        config.countryName = "SG"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"enDz-kN6SjThdQIUZRZFhy00rHQG7FmOmfFcUubxMkDNhsV3YVgivi3sCt__l1ddJnj8UiZ0_MwTWR0NCf2juA"
        }  
        config.isFreeTextForMobile=true
        config.supportEmail="cic@coca-cola.com.my"
        config.GoogleAnalyticsId = "G-17JZQSE2F6"
        config.isLatamShowWebAppBanner = true
        config.promoBannerFlag = PromoBannerSprite
        config.isSurveyEnabled = true
        config.showSupportButton = true
        config.countryDropDown=true
        config.auresyssdk="https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js"
        config.manualCorrectionMail = "cic@coca-cola.com.my"
    }
    else if(ApplicationTypeId==21){
        config.countryName = 'Hong Kong'
        config.defaultLanguage = 'Chinese'
        config.langCode = 'zh-Hans'
        config.paymentGateway = 5 // cielo=1,safer=2,d-local=3,4-paytm
        config.countryName = "HK"
        config.minAge = 14
        config.currencyCode = 'HKD'
        config.currencySymbol = 'HK$'
        config.mobileFormat = '+852 99999999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"vCh8bH49RMvNh8vu2nlPU0mfwT7jJIIy1h6Ui6fZScwj2MY290qo68P4d2jtd3GyuHW5THLaKpkP7hO2t1mK7g"
        }
        config.supportEmail = "scchkvendingcooler@swirecocacola.com" //cic@coca-cola.com.my"  
        config.postSaleAPI = false    
        config.enableCardScan = true    
        config.isMultiLangEnabled = true
        config.languageList = ["English","Chinese"]
        config.GoogleAnalyticsId = "G-9VD02SY4BL"
    }
    else if(ApplicationTypeId===22){
        config.countryName = 'Morocco'
        config.defaultLanguage = 'English'
        config.langCode = 'ar-MA'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "MA"
        config.minAge = 13
        config.currencyCode = 'MAD'
        config.currencySymbol = 'Dhs'
        config.mobileFormat = '+212 999 999 999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"YCqCFBH9v2tNI6PW50mx3sjSVOUasaOhExe0GNvEvgeHPSns2TSDWM_DvyveC0CxlyLYd9U-1bzg1nkiY79Ug"
        }  
        config.isMultiLangEnabled = true
        config.languageList = ["English","French","Arabic"]
        config.GoogleAnalyticsId = "G-0ZKSV91Z6D"
        config.supportEmail = "service.clients@nabc.eccbc.com"
    }
    else if(ApplicationTypeId===23){
        config.countryName = 'Taiwan'
        config.defaultLanguage = 'Taiwan'
        config.langCode = 'zh-TW'
        config.paymentGateway = 5 // cielo=1,safer=2,d-local=3,4-paytm
        config.countryName = "TW"
        config.minAge = 13
        config.currencyCode = 'TWD'
        config.currencySymbol = 'NT$'
        config.mobileFormat = '+886 9999-999-999'
        config.mobileLength = 13
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"YCqCFBH9v2tNI6PW50mx3sjSVOUasaOhExe0GNvEvgeHPSns2TSDWM_DvyveC0CxlyLYd9U-1bzg1nkiY79Ug"
        }  
        config.isMultiLangEnabled = true
        config.languageList = ["Taiwan","English"]
        config.GoogleAnalyticsId = "G-0ZKSV91Z6D"
        config.enableCardScan = false    
    }

    else if(ApplicationTypeId===26){
        config.countryName = 'Egypt'
        config.defaultLanguage = 'English'
        config.langCode = 'ar-EG'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3,4-paytm
        config.countryName = "EG"
        config.minAge = 13
        config.currencyCode = 'EGP'
        config.currencySymbol = 'E£'
        config.mobileFormat = '+20 9999-9999'
        config.mobileLength = 10
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.isSurveyEnabled = true
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"MEMSVzoBYfuQ0D1K7KwwkYUhbd-9-gepi6xxSHdFvAvq6HzSJU9xTZobJDAm2__gSAmcWkJMIBuB4JiJF-FzPw"
        }  
        config.isMultiLangEnabled = true
        config.languageList = ["English", "Arabic"]
        config.GoogleAnalyticsId = "G-EFT5Y2W9X5"
    }
    else if(ApplicationTypeId===24){
        config.countryName = 'Ireland'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 6 // cielo=1,safer=2,d-local=3
        config.countryName = "SG"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"enDz-kN6SjThdQIUZRZFhy00rHQG7FmOmfFcUubxMkDNhsV3YVgivi3sCt__l1ddJnj8UiZ0_MwTWR0NCf2juA"
        }  
        config.isFreeTextForMobile=true
        config.supportEmail="cic@coca-cola.com.my"
        config.GoogleAnalyticsId = "G-17JZQSE2F6"
        config.isLatamShowWebAppBanner = true
        config.promoBannerFlag = PromoBannerSprite
        config.isSurveyEnabled = true
        config.showSupportButton = true
        config.countryDropDown=true
        config.auresyssdk="https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js"
        config.manualCorrectionMail = "cic@coca-cola.com.my"
        config.loyaltyterms = 'html/Ireland.html'
    }
    else if(ApplicationTypeId===31){
        config.countryName = 'United Kingdom'
        config.defaultLanguage = 'English'
        config.langCode = 'en-UK'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "UK"
        config.minAge = 16
        config.currencyCode = 'GBP'
        config.currencySymbol = '£'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"xgoYZO3u_FV3au3rX6JXALYlHGPxNDEVLnwCtKjxHyObFY78jLw0BMruA4szKrtbDMo8dofdyilSRSFUIoP81g"
        }  
        config.GoogleAnalyticsId = "G-741Z9XCN6J"
        config.isMultiLangEnabled = false
        config.supportEmail="gb_service_engineer@ccep.com"
        config.languageList = ["English"]
        config.showSupportButton = true
        config.postSaleAPI = false
        config.isSurveyEnabled = true
        config.enableCardScan=false        
    }
    else if(ApplicationTypeId===32){
        config.countryName = 'Czech Republic'
        config.defaultLanguage = 'Czech'
        config.langCode = 'cs-CZ'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "CZ"
        config.minAge = 15
        config.currencyCode = 'CZK'
        config.currencySymbol = 'Kč'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.GoogleAnalyticsId = ""
        config.showSupportButton = true
        config.isMultiLangEnabled = true
        config.languageList = ["English","Czech"]
        config.postSaleAPI = false
        config.isSurveyEnabled = true       
        config.loyaltyterms = 'html/Czech Republic.html'
    }
}

// Prod setup
if(env==="Prod"){
    const instaProd_Api = "https://portal.instagng.com/"
    const dasboardProd = "https://dashboard.visioniot.net/"
    const socketProd = "https://instagng-ar-api.azurewebsites.net";      

    config.envUrl = instaProd_Api
    config.envDashboard = dasboardProd
    config.socketUrl = socketProd
    config.GoogleClientId = "944593527401-g3eubfej5otb8s5ptra34p1jojedes9b.apps.googleusercontent.com"
    if(ApplicationTypeId===8){
        config.FacebookAppId = '682396719481850'
        config.countryName = 'United States of America'
        config.defaultLanguage = 'English'
        config.langCode = 'en-US'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "US"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.GoogleAnalyticsId = ""
        config.showSupportButton = true
        config.isMultiLangEnabled = true
        config.languageList = ["English"]
        config.postSaleAPI = false
        config.isSurveyEnabled = true      
    }
    else if(ApplicationTypeId===9){
        config.countryName = 'Brazil'
        config.defaultLanguage = 'Portuguese'
        config.langCode = "pt-BR";
        config.paymentGateway = 1 // cielo=1,safer=2,d-local=3
        config.countryName = "BR"
        config.minAge = 14
        config.currencyCode = 'BRL'
        config.currencySymbol = 'R$'
        config.mobileFormat = '(99) 99999-9999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/AAAA"    
        config.birthDatePlaceHolder = "dd/mm/aaaa"  
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"N4ksjkB23-BcTfRtlpXYWGEP7IhwNFjWqwXAg7nZQ0ZdRE2LkM9duuu8BvvFhe45h9ZhTiAWqCuV6sUhI2Brzw"
        }  
        config.supportEmail = "sacc_faleconosco@coca-cola.com"
        config.postSaleAPI = true
        config.isSurveyEnabled = true
        config.showPendingPopup=true
        config.promoBannerFlag = promoBannerFanta
        config.GoogleAnalyticsId = "G-2B3YCLYS3P"
        config.showSupportButton = true
        config.manualCorrectionMail = "cic@coca-cola.com.my"
        config.isLatamCountry = true
    }
    else if(ApplicationTypeId===11){
        config.countryName = 'Poland'
        config.defaultLanguage = 'Polish'
        config.langCode = 'en-US'
        config.paymentGateway = 2 // cielo=1,safer=2,d-local=3
        config.countryName = "PD"
        config.minAge = 14
        config.currencyCode = 'PLN'
        config.currencySymbol = 'zł'
        config.mobileFormat = '99999999999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"fChT-r9WRqFAZ-fZR70yVGXz12bXBiCZgpO1QbWN8LF2MX9tHOX0TmL-zwCQg3X_hs_Z9m4Z03pfWl11XLeNBA"
        }  
        config.isFreeTextForMobile=true
        config.isSaferPayWalletEnabled=true
        config.GoogleAnalyticsId = "G-SE0VS3M670"
        config.supportEmail = "infopl@cchellenic.com"
        config.isSurveyEnabled = true
        config.loyaltyterms = 'html/Poland.html'
    }
    else if(ApplicationTypeId===12){
        config.countryName = 'Austria'
        config.defaultLanguage =  'German'
        config.langCode = 'en-US'
        config.paymentGateway = 2 // cielo=1,safer=2,d-local=3
        config.countryName = "AS"
        config.minAge = 14
        config.currencyCode = 'EUR'
        config.currencySymbol = '€'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"oXXXNCDWDmrDVtJuaQwU-adJbE80AYPB-QMyzQN2pd8QYSgFCd2X1GwM-OQHnKg4kr5yJ7RNdCnVLQ9YRSyGMw"
        }  
        config.isFreeTextForMobile=true
        config.isSaferPayWalletEnabled=true
        config.GoogleAnalyticsId = "G-6F3GEHFT29"
        config.supportEmail = "cscbs@cchellenic.com"
        config.loyaltyterms = 'html/Austria.html'
    }
    else if(ApplicationTypeId===13){
        config.countryName = 'Chile'
        config.defaultLanguage =  'Spanish' 
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "CH"
        config.minAge = 14
        config.currencyCode = 'CLP'
        config.currencySymbol = '$'
        config.mobileFormat = '(9) 9999-9999'
        config.mobileLength = 9
        // config.privacyPolciy = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"GqB0mokiDooSI8QvxU1plGUEfbUVFpXxcRqSfudg7qh7o-6mwUpVWeA9JeodhatZWexL078K1Upqld8wv1tisQ"
        }  
        config.supportEmail = "contactochile@coca-cola.com"  
        config.postSaleAPI = true
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-NY6DFL4WE0"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
    }
    else if(ApplicationTypeId===14){
        config.countryName = 'Mexico'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'es-MX'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "MX"
        config.minAge = 14
        config.currencyCode = 'MXN'
        config.currencySymbol = 'MEX$'
        config.mobileFormat = '(999) 9999-999'
        config.mobileLength = 10
        // config.privacyPolciy = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"fLtxkeJZuyKxqL3HZRyDhrNQSZJHZsVIDsCbiPu5TC3Ux9OLhyDglE_sEvm_CNI4fuCMzcLB2c3omRatDBhw5g"
        }  
        config.supportEmail = "consumidor@coca-cola.com"  
        config.postSaleAPI = true
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-MDZC6NQ8NC"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
    }
    else if(ApplicationTypeId===15){
        config.countryName = 'Peru'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "PR"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(999) 999-999'
        config.mobileLength = 9
        // config.privacyPolciy = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"6ejW5xVRUSIfi5x5EY-PypciiJEAb9gqXJh5rTbohj2WjKda516pTefOWK36ZX33RHlhPCLUBkYKF9iO8t71nw"
        }  
        config.supportEmail = "contactope@coca-cola.com"
        config.postSaleAPI = true
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-CC5RHX61TY"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
    }
    else if(ApplicationTypeId===16){
        config.countryName = 'Colombia'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'es-CL'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "CO"
        config.minAge = 14
        config.currencyCode = 'COP'
        config.currencySymbol = 'Col$'
        config.mobileFormat = '+57 9999999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"6ejW5xVRUSIfi5x5EY-PypciiJEAb9gqXJh5rTbohj2WjKda516pTefOWK36ZX33RHlhPCLUBkYKF9iO8t71nw"
        }  
        config.isMultiLangEnabled = true
        config.languageList = ["Spanish","English"]
        config.GoogleAnalyticsId = "G-6QQZW9MXHN"
        config.showSupportButton = true
        config.postSaleAPI = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
    }
    else if(ApplicationTypeId===17){
        config.countryName = 'South Africa'
        config.defaultLanguage = 'English'
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "SA"
        config.minAge = 18
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '99999999999999'
        config.mobileLength = 14
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"c__2-D57nS5VZpuqUj4vmcVDlBgPYufwXF0vFQ3wPcwSZjeumHX-MWpV1Cxo-Yp7g_S48TxO-Q-v1GfkdTZOjw"
        }  
        config.isFreeTextForMobile=true
        config.GoogleAnalyticsId = "G-21S31WE8PR"
    }
    else if(ApplicationTypeId===19){
        config.countryName = 'India'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 4 // cielo=1,safer=2,d-local=3
        config.countryName = "IND"
        config.minAge = 15
        config.currencyCode = 'INR'
        config.currencySymbol = '₹'
        config.mobileFormat = '9999999999'
        config.mobileLength = 10
        config.isSurveyEnabled = true
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"SbEnuJPD99-hKamfNQvpfMd5d8-RzCFEEYOVlRkd6eg_AwqCTqpMnxSSJmHEm9HBbFXW9ileXMcxXhVG-gyIRQ"
        }  
        config.GoogleAnalyticsId = "G-C067LCZCBZ"
        config.supportEmail="cokeandgosupport@coca-cola.in"
    }
    else if(ApplicationTypeId===20){
        config.countryName = 'Singapore'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 6 // cielo=1,safer=2,d-local=3
        config.countryName = "SG"
        config.minAge = 14
        config.currencyCode = 'SGD'
        config.currencySymbol = 'S$'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"gyTZPgIpHiIeuBiAY_B214ndaeat4OxI3qAu2zD_9l4Mjy8-2I64eOX68qfWrVFueYTI3vqXZFGEQPlr_ys8Qg"
        }  
        config.isFreeTextForMobile=true
        config.supportEmail="cic@coca-cola.com.my"
        config.GoogleAnalyticsId = "G-7BZ9Q21QBX"    
        config.isLatamShowWebAppBanner = true    
        config.promoBannerFlag = PromoBannerSprite
        config.isSurveyEnabled = true
        config.showSupportButton = true
        config.countryDropDown=true
        config.manualCorrectionMail = "cic@coca-cola.com.my"
    }
    else if(ApplicationTypeId==21){
        config.countryName = 'Hong Kong'
        config.defaultLanguage = 'Chinese'
        config.langCode = 'zh-Hans'
        config.paymentGateway = 5 // cielo=1,safer=2,d-local=3
        config.countryName = "HK"
        config.minAge = 14
        config.currencyCode = 'HKD'
        config.currencySymbol = 'HK$'
        config.mobileFormat = '+852 99999999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"PtKCn5p8csHEAm4X1XjLmTe51hhf61Mph0lpXQxk0-FhN51jZevnCPL4PhVqO5r6pQYMX2asjw7D3SmgME9M_A"
        }
        config.supportEmail = "scchkvendingcooler@swirecocacola.com" //cic@coca-cola.com.my"  
        config.postSaleAPI = false  
        config.enableCardScan = true    
        config.isMultiLangEnabled = true 
        config.languageList = ["English","Chinese"]    
        config.GoogleAnalyticsId = "G-0KG7LZ6JPG"
    }
    else if(ApplicationTypeId===22){
        config.countryName = 'Morocco'
        config.defaultLanguage = 'English'
        config.langCode = 'ar-MA'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "MA"
        config.minAge = 13
        config.currencyCode = 'MAD'
        config.currencySymbol = 'Dhs'
        config.mobileFormat = '+212 999 999 999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"TU4meQKuBDvDRp_TtfC37YiwCGUgS_zahM3gE1ySgeNOke_EIUxZb6iDyN3lAQq91JLo8xH0PrNY_fS5m1fKyA"
        } 
        config.isMultiLangEnabled = true 
        config.languageList = ["English","French","Arabic"]
        config.GoogleAnalyticsId = "G-7GYGZLDLGQ"
        config.supportEmail = "service.clients@nabc.eccbc.com"
    }
    else if(ApplicationTypeId===23){
        config.countryName = 'Taiwan'
        config.defaultLanguage = 'Taiwan'
        config.langCode = 'zh-TW'
        config.paymentGateway = 5 // cielo=1,safer=2,d-local=3,4-paytm
        config.countryName = "TW"
        config.minAge = 13
        config.currencyCode = 'TWD'
        config.currencySymbol = 'NT$'
        config.mobileFormat = ' +886 9999-999-999'
        config.mobileLength = 13
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"Bsv5VsA9g6HDyAemTL6jnNXuTU5nUlECGLIJMcjb8lm3wR3NKrkeA8A4FsCDAnT4vjImjqaP7wxUtQKURqHneA"
        }  
        config.isMultiLangEnabled = true
        config.languageList = ["Taiwan","English"]
        config.GoogleAnalyticsId = "G-8TQ1ZHZCXX"
        config.enableCardScan = false
    }
    else if(ApplicationTypeId===26){
        config.countryName = 'Egypt'
        config.defaultLanguage = 'English'
        config.langCode = 'ar-EG'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3,4-paytm
        config.countryName = "EG"
        config.minAge = 13
        config.currencyCode = 'EGP'
        config.currencySymbol = 'E£'
        config.mobileFormat = '+20 9999-9999'
        config.mobileLength = 13
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.isSurveyEnabled = true
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"MEMSVzoBYfuQ0D1K7KwwkYUhbd-9-gepi6xxSHdFvAvq6HzSJU9xTZobJDAm2__gSAmcWkJMIBuB4JiJF-FzPw"
        }  
        config.isMultiLangEnabled = false
        config.languageList = ["English", "Arabic"]
        config.GoogleAnalyticsId = "G-EFT5Y2W9X5"
    }
    else if(ApplicationTypeId===24){
        config.countryName = 'Ireland'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 6 // cielo=1,safer=2,d-local=3
        config.countryName = "SG"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"enDz-kN6SjThdQIUZRZFhy00rHQG7FmOmfFcUubxMkDNhsV3YVgivi3sCt__l1ddJnj8UiZ0_MwTWR0NCf2juA"
        }  
        config.isFreeTextForMobile=true
        config.supportEmail="cic@coca-cola.com.my"
        config.GoogleAnalyticsId = "G-17JZQSE2F6"
        config.isLatamShowWebAppBanner = true
        config.promoBannerFlag = PromoBannerSprite
        config.isSurveyEnabled = true
        config.showSupportButton = true
        config.countryDropDown=true
        config.auresyssdk="https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js"
        config.manualCorrectionMail = "cic@coca-cola.com.my"
        config.loyaltyterms = 'html/Ireland.html'
    }
    else if(ApplicationTypeId===31){
        config.countryName = 'United Kingdom'
        config.defaultLanguage = 'English'
        config.langCode = 'en-UK'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "UK"
        config.minAge = 16
        config.currencyCode = 'GBP'
        config.currencySymbol = '£'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"kdUttFR2rACV5qwXXfZuntfoHe8PoDti2KxfN1kDOmR9WW7lRFDGiSlsQxv9qYaxmrlVgNswspl8UCc2KeSjvw"
        }  
        config.GoogleAnalyticsId = ""
        config.isMultiLangEnabled = false
        config.supportEmail="gb_service_engineer@ccep.com"
        config.languageList = ["English"]
        config.showSupportButton = true
        config.postSaleAPI = false
        config.isSurveyEnabled = true
        config.enableCardScan=false
        config.elevonSDK="https://api.convergepay.com/hosted-payments/PayWithConverge.js"
        config.elevonURL="https://pgw.suelware.com"
    }
    else if(ApplicationTypeId===32){
        config.countryName = 'Czech Republic'
        config.defaultLanguage = 'Czech'
        config.langCode = 'cs-CZ'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "CZ"
        config.minAge = 15
        config.currencyCode = 'CZK'
        config.currencySymbol = 'Kč'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.GoogleAnalyticsId = ""
        config.showSupportButton = true
        config.isMultiLangEnabled = true
        config.languageList = ["English","Czech"]
        config.postSaleAPI = false
        config.isSurveyEnabled = true       
        config.loyaltyterms = 'html/Czech Republic.html'        
    }
}

// Pre Prod setup
if(env==="Pre-Prod"){
    const instaQA_Api = "https://portal-stg.instagng.com/"
    // const dasboardQA = "https://dashboard-qa.ebest-iot.com/"
    const socketQA = "https://instagng-ar-api-pre-prod.azurewebsites.net";
     
    config.FacebookAppId = "575511497112466"
    config.envUrl = instaQA_Api
    config.socketUrl = socketQA
    config.GoogleClientId = "1083860683602-or4eeqljecul7nchqbvg644api0n63g7.apps.googleusercontent.com";
    if(ApplicationTypeId===8){
        config.FacebookAppId = '682396719481850'
        config.countryName = 'United States of America'
        config.defaultLanguage = 'English'
        config.langCode = 'en-US'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "US"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.GoogleAnalyticsId = ""
        config.showSupportButton = true
        config.isMultiLangEnabled = true
        config.languageList = ["English"]
        config.postSaleAPI = false
        config.isSurveyEnabled = true      
    }
    else if(ApplicationTypeId===9){
        config.countryName = 'Brazil'
        config.defaultLanguage = 'Portuguese'
        config.langCode = 'en-US'
        config.paymentGateway = 1 // cielo=1,safer=2,d-local=3
        config.countryName = "BR"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(99) 99999-9999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/AAAA"    
        config.birthDatePlaceHolder = "dd/mm/aaaa"  
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"jNjX6mYuyZzrZqZHdHR7zbVJaXQHVgkCew4oaqZtVBq8bl7iuN1CedjmWHboK8xvTeDgunscjz6M-PrgYg7G3g"
        }
        config.supportEmail = "sacc_faleconosco@coca-cola.com" //cic@coca-cola.com.my"  
        config.postSaleAPI = true
        config.isSurveyEnabled = true
        config.showPendingPopup=true
        config.promoBannerFlag = promoBannerFanta
        config.GoogleAnalyticsId = "G-ZRGZ69W491"
        config.showSupportButton = true
        config.manualCorrectionMail = "cic@coca-cola.com.my"
        config.isLatamCountry = true
        cdsConfig.clientID="fee38661-2bc8-4b42-bd19-728107cf0e78"
    }
    else if(ApplicationTypeId===11){
        config.countryName = 'Poland'
        config.defaultLanguage = 'Polish'
        config.langCode = 'en-US'
        config.paymentGateway = 2 // cielo=1,safer=2,d-local=3
        config.countryName = "PD"
        config.minAge = 14
        config.currencyCode = 'PLN'
        config.currencySymbol = 'zł'
        config.mobileFormat = '99999999999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"aOqDf4ovhZsT7_ZmyLhph3tbsJT4TUtVAVK7hnAyu9IaIg9IHUVSasnDTteEunYQkiycfwfZ_b8jo1H0pmayCw"
        }  
        config.isFreeTextForMobile=true
        config.isSaferPayWalletEnabled=true
        config.GoogleAnalyticsId = "G-8Q4TXV7VVS"
        config.supportEmail = "infopl@cchellenic.com"
        config.isSurveyEnabled = true
        config.loyaltyterms = 'html/Poland.html'
    }
    else if(ApplicationTypeId===12){
        config.countryName = 'Austria'
        config.defaultLanguage =  'German'
        config.langCode = 'en-US'
        config.paymentGateway = 2 // cielo=1,safer=2,d-local=3
        config.countryName = "AS"
        config.minAge = 14
        config.currencyCode = 'EUR'
        config.currencySymbol = '€'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"JcnTJHDJCKkdYHHhQu_jVCQi-mtwNjr1J7yztAqJyrBIctSQa8uEpp70r7Z-atB6QyLdJYSHgVpS-CAknnDxag"
        } 
        config.isFreeTextForMobile=true 
        config.isSaferPayWalletEnabled=true
        config.GoogleAnalyticsId = "G-YC358E3K7J"
        config.supportEmail = "cscbs@cchellenic.com"
        config.isSurveyEnabled = true
        config.loyaltyterms = 'html/Austria.html'
    }
    else if(ApplicationTypeId===13){
        config.countryName = 'Chile'
        config.defaultLanguage =  'Spanish' 
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "CH"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(9) 9999-9999'
        config.mobileLength = 9
        // config.privacyPolciy = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.cl/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"6fHvzyhXOA3pz1Ws3NgIjY35VLNxVn7iLNn1Oy9ffYtmCPWsk7tB8BFHJUz-uODCxJeRHz0Cj89A0QHZuVyXNA"
        }  
        config.supportEmail = "cic@coca-cola.com.my" 
        config.postSaleAPI = true 
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-C6YJTXXFVP"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
        cdsConfig.clientID="cd01b146-09d0-48f1-a00a-0c01d6f98514"
    }
    else if(ApplicationTypeId===14){
        config.countryName = 'Mexico'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'en-US' // for prod es-MX
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "MX"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(999) 9999-999'
        config.mobileLength = 10
        // config.privacyPolciy = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.com.mx/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"LsqFWxKO0UC_mCrL9J4xSK8mi-zohXKEnmn22vGKigirp3WX2UlE_LTFqWbmbdOtHw1mSi68TOWz1fWPHevSLg"
        }  
        config.supportEmail = "cic@coca-cola.com.my"  
        config.postSaleAPI = true
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-0RWB1VZW16"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
        cdsConfig.clientID="d4c057e9-21fb-443c-af45-e8952eb2a8ae"
    }
    else if(ApplicationTypeId===15){
        config.countryName = 'Peru'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "PR"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '(999) 999-999'
        config.mobileLength = 9
        // config.privacyPolciy = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.termsOfUseAndSale = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.termsOfUse = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        // config.marketingTerms = "https://www.coca-cola.com.pe/es/términosycondiciones/coke-and-go"
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"qoeL6KhZodupDFQP8O0CUNDt6vfCMw0JY4MhqEhyNsy-tyuUS-igd1pSENaAr1yzW5_3mkTSWl3ZWCbsVpwTmQ"
        }  
        config.supportEmail = "contactope@coca-cola.com"
        config.postSaleAPI = true
        config.promoBannerFlag = promoBannerCoke
        config.GoogleAnalyticsId = "G-PQZPMYBWY9"
        config.showSupportButton = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
        cdsConfig.clientID="e40af4fb-e115-418f-b522-dab77f89c4f7"
    }
    else if(ApplicationTypeId===16){
        config.countryName = 'Colombia'
        config.defaultLanguage = 'Spanish'
        config.langCode = 'es-CL'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "CO"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = 'Col$'
        config.mobileFormat = '+57 9999999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/AAAA" 
        config.birthDatePlaceHolder = "dd/mm/aaaa"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"Zrg90eZ6xxnmscb9q2H4VBJXVOP1coWz3mDNtYvkC8l3V1ArtXZ3gqp_8l16E7tKA3i2Ikyq8M3bNC44elVdfg"
        }  
        config.GoogleAnalyticsId = "G-J661WXN6V0"
        config.isMultiLangEnabled = true
        config.languageList = ["Spanish","English"]
        config.showSupportButton = true
        config.postSaleAPI = true
        config.isSurveyEnabled = true
        config.isLatamCountry = true
        cdsConfig.clientID="be4c9563-114f-4cd2-876d-15fd6f81ce0e"
    }
    else if(ApplicationTypeId===17){
        config.countryName = 'South Africa'
        config.defaultLanguage = 'English'
        config.langCode = 'en-US'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "SA"
        config.minAge = 18
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '99999999999999'
        config.mobileLength = 14
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"GYgnQydK1uOHgoXa6b2ibM2D61KwxpT0gu4hjGGQIjExim-ATSC_YxWeO1_cdL1l1TMnvjLx20k0ChgInatC8A"
        }  
        config.isFreeTextForMobile=true
        config.GoogleAnalyticsId = "G-124N5HKP22"
    }
    else if(ApplicationTypeId===19){
        config.countryName = 'India'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 4 // cielo=1,safer=2,d-local=3
        config.countryName = "IND"
        config.minAge = 15
        config.currencyCode = 'INR'
        config.currencySymbol = '₹'
        config.mobileFormat = '9999999999'
        config.mobileLength = 10
        config.isSurveyEnabled = true
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"L1neIonf9Ia1QjVmucsgRST1JR_SfHiMiL4L5JpeMx8QTwrBcODX7U5wPLdsLYmKgiLrviYyAYjqP0L1M2_MgA"
        }  
        config.GoogleAnalyticsId = "G-BJ6JP89G85"
        config.supportEmail="cokeandgosupport@coca-cola.in"
    }
    else if(ApplicationTypeId===20){
        config.countryName = 'Singapore'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 6 // cielo=1,safer=2,d-local=3
        config.countryName = "SG"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"y_lg3yT3YNpSEsY4cvPnJ72C6poKoUEWSsY1Dj0vY4aUXOppZYvFClkHjHRRHefrNfipsgnYyR7dD3n42xSWDw"
        }  
        config.isFreeTextForMobile=true
        config.supportEmail="cic@coca-cola.com.my"
        config.GoogleAnalyticsId = "G-QT33BM5NJG"
        config.isLatamShowWebAppBanner = true
        config.promoBannerFlag = PromoBannerSprite
        config.isSurveyEnabled = true
        config.showSupportButton = true
        config.countryDropDown=true
        config.auresyssdk="https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js"
        config.manualCorrectionMail = "cic@coca-cola.com.my"
    }
    else if(ApplicationTypeId==21){
        config.countryName = 'Hong Kong'
        config.defaultLanguage = 'Chinese'
        config.langCode = 'zh-Hans'
        config.paymentGateway = 5 // cielo=1,safer=2,d-local=3,4-paytm
        config.countryName = "HK"
        config.minAge = 14
        config.currencyCode = 'HKD'
        config.currencySymbol = 'HK$'
        config.mobileFormat = '+852 99999999'
        config.mobileLength = 11
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"QhO8bDB9AJwc74ZMc9RGub7lDMcIfVqWzfT9g67M55Yue0MaCb033Z5kQ7dcrsg4ZDUzC6917XA-fxLlRuqjVQ"
        }
        config.supportEmail = "scchkvendingcooler@swirecocacola.com" //cic@coca-cola.com.my"  
        config.postSaleAPI = false    
        config.enableCardScan = true    
        config.isMultiLangEnabled = true
        config.languageList = ["English","Chinese"]
        config.GoogleAnalyticsId = "G-C7R6TMDTW4"
    }
    else if(ApplicationTypeId===22){
        config.countryName = 'Morocco'
        config.defaultLanguage = 'English'
        config.langCode = 'ar-MA'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3
        config.countryName = "MA"
        config.minAge = 13
        config.currencyCode = 'MAD'
        config.currencySymbol = 'Dhs'
        config.mobileFormat = '+212 999 999 999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"6uGU5yZ7x2xzE-IBDMZzgKwx4ZvAR0RdUtZUvLbUiqtCjc1Dq04EIduSHtGgraUhDoaRLCClt52ymMlqy7OKCw"
        }  
        config.isMultiLangEnabled = true
        config.languageList = ["English","French","Arabic"]
        config.GoogleAnalyticsId = "G-DSZGCXJM66"
        config.supportEmail = "service.clients@nabc.eccbc.com"
    }
    else if(ApplicationTypeId===23){
        config.countryName = 'Taiwan'
        config.defaultLanguage = 'Taiwan'
        config.langCode = 'zh-TW'
        config.paymentGateway = 5 // cielo=1,safer=2,d-local=3,4-paytm
        config.countryName = "TW"
        config.minAge = 13
        config.currencyCode = 'TWD'
        config.currencySymbol = 'NT$'
        config.mobileFormat = '+886 9999-999-999'
        config.mobileLength = 13
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"4O7qS_hnwc8D8YhRlJiDj4FMfsEb5UXQ-Qsv3NlDwp5i06K6o0N8tucobtYwa55T57YaxcrDSozwN8XRCbEy8w"
        }  
        config.isMultiLangEnabled = true
        config.languageList = ["Taiwan","English"]
        config.GoogleAnalyticsId = "G-W20S3E82FC"
        config.enableCardScan = false
    }

    else if(ApplicationTypeId===26){
        config.countryName = 'Egypt'
        config.defaultLanguage = 'English'
        config.langCode = 'ar-EG'
        config.paymentGateway = 3 // cielo=1,safer=2,d-local=3,4-paytm
        config.countryName = "EG"
        config.minAge = 13
        config.currencyCode = 'EGP'
        config.currencySymbol = 'E£'
        config.mobileFormat = '+20 9999-9999'
        config.mobileLength = 10
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.isSurveyEnabled = true
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"GTMImdYZpcoZdnDsGdJk0xz5Su6rgAv30RGEVIYJHdRFELltV8XkpLr5_R4JYcbs7UHzJhTA8p6bLQRRndR9ow"
        }  
        config.isMultiLangEnabled = true
        config.languageList = ["English", "Arabic"]
        config.GoogleAnalyticsId = "G-62X2CV4E9B"
    }
    else if(ApplicationTypeId===24){
        config.countryName = 'Ireland'
        config.defaultLanguage = 'English'
        config.langCode = 'en-IN'
        config.paymentGateway = 6 // cielo=1,safer=2,d-local=3
        config.countryName = "SG"
        config.minAge = 14
        config.currencyCode = 'USD'
        config.currencySymbol = '$'
        config.mobileFormat = '999999999999999'
        config.mobileLength = 15
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"enDz-kN6SjThdQIUZRZFhy00rHQG7FmOmfFcUubxMkDNhsV3YVgivi3sCt__l1ddJnj8UiZ0_MwTWR0NCf2juA"
        }  
        config.isFreeTextForMobile=true
        config.supportEmail="cic@coca-cola.com.my"
        config.GoogleAnalyticsId = "G-17JZQSE2F6"
        config.isLatamShowWebAppBanner = true
        config.promoBannerFlag = PromoBannerSprite
        config.isSurveyEnabled = true
        config.showSupportButton = true
        config.countryDropDown=true
        config.auresyssdk="https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js"
        config.manualCorrectionMail = "cic@coca-cola.com.my"
        config.loyaltyterms = 'html/Ireland.html'
    }
    else if(ApplicationTypeId===31){
        config.countryName = 'United Kingdom'
        config.defaultLanguage = 'English'
        config.langCode = 'en-UK'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "UK"
        config.minAge = 16
        config.currencyCode = 'GBP'
        config.currencySymbol = '£'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.bioMetric={
            baseURL:"https://directweb.usw1.loginid.io",
            clientID:"3h_UL0ua8cz1wawEaVjFFqE3C3lKHbMvSsYdpZIF-cBLCb10evVkj21cpJWQRMGsrko9WuV3IKWvzNt7hbTL5g"
        }  
        config.GoogleAnalyticsId = ""
        config.isMultiLangEnabled = false
        config.supportEmail="gb_service_engineer@ccep.com"
        config.languageList = ["English"]
        config.showSupportButton = true
        config.postSaleAPI = false
        config.isSurveyEnabled = true
        config.enableCardScan=false
        config.elevonSDK="https://api.convergepay.com/hosted-payments/PayWithConverge.js"
        config.elevonURL="https://pgw.suelware.com"
    }
    else if(ApplicationTypeId===32){
        config.countryName = 'Czech Republic'
        config.defaultLanguage = 'Czech'
        config.langCode = 'cs-CZ'
        config.paymentGateway = 8 // cielo=1,safer=2,d-local=3
        config.countryName = "CZ"
        config.minAge = 15
        config.currencyCode = 'CZK'
        config.currencySymbol = 'Kč'
        config.mobileFormat = '+44 9999 999999'
        config.mobileLength = 12
        config.expiryDatePlaceHolder = "MM/YYYY"
        config.birthDatePlaceHolder = "dd/mm/yyyy"
        config.GoogleAnalyticsId = ""
        config.showSupportButton = true
        config.isMultiLangEnabled = true
        config.languageList = ["English","Czech"]
        config.postSaleAPI = false
        config.isSurveyEnabled = true       
        config.loyaltyterms = 'html/Czech Republic.html'   
    }
}
if(window.location.hostname==="localhost"){
    config.bioMetric = {
        baseURL:"https://directweb.usw1.loginid.io",
        clientID:"vI3vMOgc02VzYvwwqgHvbqq4uSGA6eGzG5xsLCK4uRt2dT9RslXEksRqs6VSUZp-LZgdg4udqe98cvqYuFIq8A"
    }
}

export { config,PaymentGatewayList,cdsConfig }