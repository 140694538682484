import React, { Component } from 'react'
import i18next from 'i18next';
import { cardService } from '../_services/card.service';
import { ClipLoader } from 'react-spinners';
import ConfirmPopup from '../components/ConfirmPopup/ConfirmPopup';
import { history } from '../_helpers';
import { config } from '../_config';
import ModelPopup from "../components/Model-Popup/Popup";
import { Link } from 'react-router-dom';
import trash from '../_assets/images/icons/delete.png'
import country  from "iso-country-currency"
import FooterNav from '../components/FooterNav/FooterNav';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from '../_helpers/encryptData';
import OtpPopUp from '../components/OtpPopUp/OtpPopUp';
import { purchaseHistoryService } from '../_services';
import CardValidation from '../components/CardValidation/CardValidation';

class PayBalance extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | PayOutstandingbalance`;
        CapturePage({hitType:"pageview",page:"/PayOutstandingbalance/:id"})    
        
        if(props.location.state=='ordersummary'){
            sessionStorage.setItem('ordersummary','true')
        }
        this.state = {
            isLoading:true,
            isDisabled:false,
            cardList:[],
            cardID:'',
            popupmsg:'',
            showPopUp:false,
            imagetype:'error',
            errorCode:0,
            isOffline:navigator.onLine?false:true,
            ErrorCode:'',
            purchaseNumber: props.match.params.id,
            PayUDesc:"",
            showPayUModal:false,
            isOtpButtonDisable:true,
            PayUOTP:"",
            PayUData:{},
            isOTPVerified:false,
            apiPopup:false,
            popupmsg:'',
            paymentGateway:'',
            paymentFlow:'OLD',
            orderPG:'',
            showCardValidation:false,
            cvv:'',
            invalidCVV:false,
            paytmTransactionID:""
        }
        let that = this;
        
        this.CompletependingPayment = this.CompletependingPayment.bind(this);
        this.closingShowPopup = this.closingShowPopup.bind(this);
        this.selectCard = this.selectCard.bind(this);
        this.Addcard = this.Addcard.bind(this);
        this.storeCard = this.storeCard.bind(this);
        this.handleBack = this.handleBack.bind(this);        
    }
    componentDidMount(){
        let token = sessionStorage.getItem('token');
        if(token && config.paymentGateway==2){
            this.storeCard();
            this.setState({
                isLoading:true
            })
        }
        else{
            if(config.applicationTypeId==19){
                this.getDeviceInf();
            }
            else{
                this.getCardList();
            }
        }
    }

    selectCard(data){
        if(config.applicationTypeId==19){
            let card = this.state.cardList.filter(item=>item.cardID==data.target.value)
            let {paymentFlow='OLD'} = card[0]
            this.setState({
                paymentFlow:paymentFlow
            })
        }
        this.setState({
            cardID:data.target.value
        })
    }

    Addcard(){
        let that = this;
        let defaultPaymentGateway = config.applicationTypeId==19?this.state.paymentGateway:sessionStorage.getItem('paymentGateway')
        if(config.applicationTypeId==19 && defaultPaymentGateway=="payu" && this.state.paymentFlow=="NEW"){
            sessionStorage.setItem('cardAddedFromPending',that.state.purchaseNumber)
            let user = JSON.parse(decryptData(localStorage.getItem("user")));
            let qrcode = decryptData(localStorage.getItem("qrcode"));
            let authToken = user.authToken;
            window.location.href=`${config.envUrl}VendingAppApi/requestForPayuConsent?authToken=${authToken}&deviceId=${qrcode}&returnURL=${window.location.origin+"/payment-status"}`
        }
        else if (config.applicationTypeId==19 && defaultPaymentGateway=="paytm" && this.state.paymentFlow=='WALLET') {
            history.push({pathname:'/link-with-paytm',state:this.state.purchaseNumber})
        }
        else if (config.applicationTypeId==19 && defaultPaymentGateway=="paytm" && this.state.paymentFlow=='CARD') {
            history.push({pathname:'/addCard',state:this.state.purchaseNumber})
        }
        else if(config.paymentGateway==1 || config.paymentGateway==3 || config.paymentGateway==6 || config.paymentGateway==9 || (config.applicationTypeId==19 && defaultPaymentGateway=="payu" && that.state.paymentFlow=="OLD") || config.paymentGateway==8){
            history.push({pathname:'/addCard',state:this.state.purchaseNumber})
        }
        else if(config.paymentGateway==5){
            that.setState({isLoading:true})  
            const applicationTypeId = config.applicationTypeId;
            const redirectURL = config.envUrl+"VendingAppApi/getResponsefromHK"; //https://portal-qa.instagng.com/VendingAppApi/getResponsefromHK
            const returnURL = window.location.origin+"/payment-status" //https://portal-qa.instagng.com/VendingAppApi/getResponsefromHK
            let user = JSON.parse(decryptData(localStorage.getItem("user")));
            let authToken = user.authToken;
            let sessionUId = sessionStorage.getItem("sessionId");
            let RequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({authToken,applicationTypeId, sessionUId, redirectURL,returnURL})
            }
            fetch(`${config.envUrl}VendingAppApi/getRequestParametersForHK`, RequestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                if(result.success)
                {
                    sessionStorage.setItem('cardAddedFromPending',this.state.purchaseNumber)
                    sessionStorage.setItem('returnData',JSON.stringify(result.returnData));
                    history.push("/AddCard");
                }
                else{
                    console.log(result)
                }
            });
        }
        else{
            let that = this;
            that.setState({isLoading:true})            
            let user = JSON.parse(decryptData(localStorage.getItem("user")));
            let authToken = user.authToken;
            const applicationTypeId = config.applicationTypeId;
            const returnSuccessUrl = config.baseURL+'/PayOutstandingbalance/'+this.state.purchaseNumber;
            let sessionUId = sessionStorage.getItem('sessionId');
            let RequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({authToken,applicationTypeId, returnSuccessUrl,sessionUId})
            }
        
            fetch(`${config.envUrl}VendingAppApi/addPayment`, RequestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                if(result.success && result.returnCode==5041){
                    sessionStorage.setItem('token',result.token);
                    window.location = result.redirectURL;
                }
                else{
                    if(result.code==401){
                        that.setState({
                            isLoading:false,
                            errorCode:result.code,
                            imagetype:'error',
                            showPopUp:true,
                            popupmsg:result.message
                        })
                    }
                    else{
                        that.setState({
                            isLoading:false,
                            errorCode:result.code,
                            imagetype:'error',
                            showPopUp:true,
                            popupmsg:result.message
                        })
                    }
                }
            }).catch((error) =>{
                that.setState({
                    isLoading:false,
                    imagetype:'error',
                    showPopUp:true,
                    popupmsg:i18next.t('CommonError')
                })
            });
        }        
    }

    storeCard(){
        this.setState({
            isLoading:true
        })
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let authToken = user.authToken;
        let that = this;
        let token = sessionStorage.getItem('token');
        const applicationTypeId = config.applicationTypeId;
        let sessionUId = sessionStorage.getItem("sessionId");
        let RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({authToken,token,applicationTypeId,sessionUId})
        }
    
        fetch(`${config.envUrl}VendingAppApi/fetchAliasDetailsForCCH`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            sessionStorage.removeItem('token');
            if(result.success && result.code==5022){                
                that.setState({
                    imagetype:'success',
                    showPopUp:true,
                    message:i18next.t('CardSuccess'),
                    isLoading:false
                })
            }
            else{
                that.setState({
                    imagetype:'error',
                    showPopUp:true,
                    message:result.message,
                    errorCode:result.code,
                    isLoading:false
                })
            }
        }).catch((error)=> {
            sessionStorage.removeItem('token');
            that.setState({
                imagetype:'error',
                showPopUp:true,
                message:error.message,
                errorCode:error.code,
                isLoading:false
            })
        });
    }

    getCardList=(qrcode)=>{
        let that=this;
        cardService.getCardList(qrcode).then(function(result){         
            if(result.success){
                result.cardList.map((card,key)=>{
                    if(card.isDefault){
                        that.setState({
                            cardID : card.cardID
                        })
                    }
                })
                sessionStorage.setItem('paymentGateway',result.paymentGateway.toLowerCase())
                sessionStorage.setItem('paymentFlow',result.paymentFlow)
                that.setState({
                    cardList : result.cardList,
                    isLoading:false,
                    showPopUp:false,
                    paymentGateway:result.paymentGateway.toLowerCase(),
                    paymentFlow:result.paymentFlow?result.paymentFlow:"OLD"
                })                
                // if(config.applicationTypeId==19)
                // {
                //     that.getDeviceInf()
                // }
            }
            else{
                if(result.code==401){
                    that.setState({
                        isLoading:false,
                        errorCode:result.code,
                        imagetype:'error',
                        showPopUp:true,
                        popupmsg:result.message
                    })
                }
                else{
                    that.setState({
                        isLoading:false,
                    })
                }
            }
        })  
    }

    getDeviceInf=()=>{
        let that = this;
        var userData = JSON.parse(decryptData(localStorage.getItem("user")));
        var historyobj = {
            authToken: userData.authToken,
            userId: userData.userId,
            purchaseNumber:this.state.purchaseNumber
        };
        purchaseHistoryService.getPurchaseHistory(historyobj).then((result) => {
            if(result.success){
                if(result.data){
                    const devicePG = result.data[0].paymentMethods.filter(item=>item.code=='PAYU').length>0?'payu':'paytm';
                    // if (devicePG == 'paytm'){                        
                        
                    //     // sessionStorage.setItem('qrcode',result.data[0].macAddress)
                    //     // history.push({pathname:'/link-with-paytm',state:that.state.purchaseNumber})
                    // }
                    // else{
                    //     let cardData = that.state.cardList.filter(item=>item.paymentGateway.toLowerCase()==devicePG).filter(item=>item.isDefault==true);
                        that.setState({
                            orderPG:devicePG
                        })
                    
                    sessionStorage.setItem('qrcode',result.data[0].macAddress)
                    that.getCardList(result.data[0].macAddress)
                }
                else{
                    history.push('/Scanbutton')
                }
            }
            else{
                history.push('/Scanbutton')
            }
        })
    }

    CompletependingPayment() {
        this.setState({
          isLoading: true,
        });
        let {orderPG,paymentFlow}= this.state
        if(orderPG=='payu' && paymentFlow=='OLD'){
            this.CompletePaymentForPayU("")
            return false
        }
        if(orderPG=='paytm' && paymentFlow=='CARD'){
            this.setState({
                showCardValidation:true,
                isLoading: false
            })
            return false
        }
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let authToken = user.authToken;
        let PurchaseNumber = this.state.purchaseNumber;
        var self = this;
        let cardId = this.state.cardID
        const CustomerName = user.firstName+' '+user.lastName;
        const applicationTypeId = config.applicationTypeId;
        const currencyCode = config.currencyCode
        const uniqueDeviceId = user.userId
        const languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
        let sessionUId = sessionStorage.getItem('sessionId');

        fetch(config.envUrl + "VendingAppApi/completePendingPaymentWithSwipe", {
          method: "POST",
          body: new URLSearchParams({
            authToken,
            PurchaseNumber,
            cardId,
            CustomerName,
            applicationTypeId,
            currencyCode,
            uniqueDeviceId,
            languageCode,
            sessionUId
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
          return response.json();
        })
        .catch(function (error) {
          if (error == "TypeError: Failed to fetch") {
            self.setState({
              isLoading:false,
              connectionLost: true,
              paymentInitiated: false,
            });
          }
        })
        .then(function (body) {
          if(body.success){
            if(config.postSaleAPI){
                self.sendPostSaleAPI();
            }
            self.setState({
                isLoading:false,
                imagetype:"success",
                message:i18next.t("PaymentSuccess"),
                showPopUp:true
            })
          }
          else{
            self.setState({
              errorCode: body.code,
              message: i18next.t("CommonError"),
              imagetype:"error",          
              showPopUp:true,
              isLoading:false
            })
          }
        });    
    }
    sendPostSaleAPI(){
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let authToken = user.authToken
        let applicationTypeId = config.applicationTypeId
        let orderid = this.state.purchaseNumber
        let sessionUId = sessionStorage.getItem('sessionId');
        let PurchaseRequestOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({authToken,applicationTypeId,orderid,sessionUId})
        }
        fetch(`${config.envUrl}VendingAppApi/callCWIPostSalePurchaseOrder`, PurchaseRequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            
        })
    }
    closingShowPopup(){
        if(this.state.imagetype=='success' && this.state.message!=i18next.t('CardSuccess')){
            const profile = sessionStorage.getItem('clickedDelete');
            const ordersummary = sessionStorage.getItem('ordersummary');
            const transactionFail = sessionStorage.getItem('transactionFail');
            if(profile=='true'){
                sessionStorage.removeItem('clickedDelete');
                history.push('/Profile');
            }
            else if(transactionFail=="true"){
                sessionStorage.removeItem("transactionFail")
                history.push({
                    pathname:"/OrderSummary/"+this.state.purchaseNumber
                    // state:"fromHome"
                })
            }
            else if(ordersummary=='true'){
                history.push('/OrderSummary/'+this.state.purchaseNumber)
            }
            else{
                history.push('/ScanButton');
            }
        }
        else if(this.state.message==i18next.t('CardSuccess')){
            this.setState({
                isLoading:true,
                showPopUp:false
            })
            this.getCardList();
        }
        else{
            this.setState({
                isLoading:false,
                showPopUp:false
            })
        }
    }

    handleBack(){
        // const profile = sessionStorage.getItem('clickedDelete');
        // if(profile=='true'){
        //     // sessionStorage.removeItem('clickedDelete');
        //     history.push('/PendingAmount');
        // }
        // else{
        //     history.push('/ScanButton');
        // }
        if(this.props.location.state=="fromHome"){
            history.push({
                pathname:"/OrderSummary/"+this.state.purchaseNumber,
                state:"fromHome"
            })
        }
        else{
            history.push({
                pathname:"/OrderSummary/"+this.state.purchaseNumber
            })
        }
    }

    CompletePaymentForPayU=(preAuthTransactionId)=>{
        const {PayUData:{referenceId,transactionID},purchaseNumber:txId,cardID} = this.state;
        const user = JSON.parse(decryptData(localStorage.user))
        let authToken=user.authToken;
        let PurchaseNumber = txId
        // let defaultcard = this.state.cardList.filter((item)=>(item.isDefault)?item:null)      
        let cardId=cardID
        let CustomerName=user.firstName+" "+user.lastName
        let applicationTypeId=config.applicationTypeId
        let currencyCode=config.currencyCode
        let uniqueDeviceId=user.userId
        let sessionUId=sessionStorage.getItem("sessionId");
        let isOTPVerified=preAuthTransactionId==""?false:true
        // let preAuthTransactionId=transactionID
        let that=this;
        let languageCode=localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
        fetch(config.envUrl + "VendingAppApi/completePendingPaymentWithSwipe", {
          method: "POST",
          body: new URLSearchParams({
            authToken,
            PurchaseNumber,
            cardId,
            CustomerName,
            applicationTypeId,
            currencyCode,
            uniqueDeviceId,
            languageCode,
            sessionUId,
            preAuthTransactionId,
            isOTPVerified
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
          return response.json();
        })
        .then(res=>{
          const {referenceId="",otpPostUrl="",success,transactionID} = res;
            if(success && referenceId!="" && otpPostUrl!=""){
                that.setState({
                    isLoading:false,
                    showPayUModal:true,
                    PayUData:{
                        referenceId:referenceId,
                        otpPostUrl:otpPostUrl,
                        transactionID:transactionID
                    }
                })
            }
            else{
                history.push({
                    pathname: '/OrderSummary/' + PurchaseNumber,
                    state: 'fromHome'
                }); 
            }
        
        })
    }

    payPendingAmount=()=>{
        let self = this;
        self.setState({
            isLoading:true
        })
        const {cardList,cvv,paytmTransactionID,purchaseNumber} = self.state
        const user = JSON.parse(decryptData(localStorage.user))
        let authToken=user.authToken;
        let PurchaseNumber = purchaseNumber
        let defaultcard = cardList.filter((item)=>(item.isDefault)?item:null)      
        let cardId=defaultcard[0].cardID
        let CustomerName=user.firstName+" "+user.lastName
        let applicationTypeId=config.applicationTypeId
        let uniqueDeviceId=user.userId
        let sessionUId=sessionStorage.getItem("sessionId");
        let isOTPVerified=false
        let currencyCode=config.currencyCode
        let preAuthTransactionId=purchaseNumber
        let languageCode=localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
        fetch(config.envUrl + "VendingAppApi/completePendingPaymentWithSwipe", {
        method: "POST",
        body: new URLSearchParams({
            authToken,
            PurchaseNumber,
            cardId,
            CustomerName,
            applicationTypeId,
            currencyCode,
            uniqueDeviceId,
            languageCode,
            sessionUId,
            preAuthTransactionId,
            isOTPVerified,
            cvv
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        })
        .then(function (response) {
          return response.json();
        })
        .then(result=>{
          const {actionUrl="",content,success} = result;
          if(success && actionUrl!=""){
            localStorage.setItem('pendingPurchaseNumber',PurchaseNumber)
            const postUrl = actionUrl;
            const errorUrl = config.baseURL + '/payment-status';
            const urlData = JSON.parse(content);
            const paytmUrl = config.envUrl+'paytm.aspx?MD='+urlData.MD+'&PaReq='+urlData.PaReq+'&TermUrl='+urlData.TermUrl+'&postURL='+postUrl+'&errorURL='+errorUrl
            window.location = paytmUrl
          }
          else{
            self.errorCode = result.code
            let code = result.code;
            let errormsg = "";
            if (code === 15525) {
                errormsg = i18next.t('CardVerificationFail')
            }
            else {
                errormsg = i18next.t('Network Error, Please re try to Submit the CVV.')          
            }
            self.setState({
                apiPopup:true,
                isLoading:false,
                popupmsg:errormsg,
                showCardValidation:false
            })
          }
        })
        .catch(err=>{
            self.setState({
                apiPopup:true,
                isLoading:false,
                popupmsg:"Network Error, Please re try to Submit the CVV.",
                showCardValidation:false
            })
        })
    }

    handleVerifyOTP=()=>{
        try {
          let that = this;
          that.setState({
            isLoading:true
          })
          const {PayUOTP:otpCode,PayUData:{transactionID,referenceId,otpPostUrl},isPayuHavePendingAmt} = that.state
          const user = JSON.parse(decryptData(localStorage.user))
          const authToken = user.authToken
          let sessionUId = sessionStorage.getItem("sessionId");
          let applicationTypeId = config.applicationTypeId;
          let deviceId = decryptData(localStorage.getItem('qrcode'));
          let RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({applicationTypeId,authToken,sessionUId,otpCode,transactionID,referenceId,otpPostUrl,deviceId})
          }
          // checking device status
          fetch(`${config.envUrl}VendingAppApi/submitPayuOTP`, RequestOptions)
          .then(function (response) {
            return response.json();
          })
          .then((result)=>{
            if(result.success){
              this.CompletePaymentForPayU(result.transactionID)
            }
            else if(result.code==1525){
                that.setState({
                  apiPopup:true,
                  isLoading: false,
                  showPayUModal:false,
                  popupmsg:"Please enter the valid OTP"
                })
            }
            else{
              that.setState({
                apiPopup:true,
                isLoading: false,
                showPayUModal:false,
                popupmsg:i18next.t("CommonError"),
              })
            }
          })
        } catch (error) {
          
        }
    }
    handleChangeOTP=(value)=>{
        this.setState((prevState)=>({
          ...prevState,
          PayUOTP:value,
          isOtpButtonDisable:value.length==6?true:false
        }))
    }
    closingApiPopup=()=>{
        this.setState({
            apiPopup:false,
        })
        // let {orderPG,paymentFlow}= this.state
        // if(orderPG=='paytm' && paymentFlow=='CARD'){
        //     history.push('/Scanbutton')
        // }

    }
    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex:'999'
          };
        let {paymentGateway:defaultPaymentGateway,orderPG,cardList} = this.state
        // let qrcode = decryptData(localStorage.getItem('qrcode'))
        return (
            <>
            <div className="Paybalance">
                <div className="container">
                <div className="mt-3" style={{height:window.innerHeight-180,overflowY:'scroll'}}>
                    <div className="row">
                    {
                        (this.state.cardList.length>0)?
                        this.state.cardList.map((card,key)=>{
                            let newCard = sessionStorage.getItem("newCard")
                            if(newCard==card.cardID){
                            return (
                                <div className="col-md-4 mb-3" key={key}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <label className='d-flex' htmlFor={card.cardID}>
                                                    <div style={{padding:'30px 0'}} className="mr-2">
                                                        <input type="radio" name="paywith" id={card.cardID} style={{height:'15px',width:'15px'}} value={card.cardID} onChange={this.selectCard} defaultChecked/>
                                                    </div>
                                                    <div className="custom-card">
                                                        <h5>{config.applicationTypeId==19 && defaultPaymentGateway=='paytm' ? `+91 ${card.phoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}`:`XXXX XXXX XXXX ${card.lastDigits}`}</h5>
                                                        <div className="py-3 pl-2 mt-1">
                                                            <span>{card.cardType}</span> 
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            }
                        }):''
                    }
                    {
                        (this.state.cardList.length>0)?
                        this.state.cardList.map((card,key)=>{
                            let newCard = sessionStorage.getItem("newCard")
                            if(newCard!=card.cardID){
                            return (
                                    <div className="col-md-4 mb-3" key={key}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div>
                                                    <label  className='d-flex' htmlFor={card.cardID}>
                                                        <div style={{padding:'30px 0'}} className="mr-2">
                                                            <input type="radio" name="paywith" id={card.cardID} style={{height:'15px',width:'15px'}} value={card.cardID} onChange={this.selectCard} disabled={(config.applicationTypeId==19 && orderPG!=card.paymentGateway.toLowerCase())?true:false}/>
                                                        </div>
                                                        <div className="custom-card" style={{backgroundColor:'#555'}}>
                                                            <h5>XXXX XXXX XXXX {card.lastDigits}</h5>
                                                            <div className="py-3 pl-2 mt-1">
                                                                {
                                                                    (card.cardType=='wallet' && config.applicationTypeId==19) ?
                                                                        <span style={{textTransform:'capitalize'}}>Paytm</span>
                                                                        :
                                                                        <span>{card.cardType}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            ) 
                            }
                        })
                        : 
                        <div className="emptycard">
                            <h4 className="text-center">{i18next.t("CardEmpty")}</h4>
                        </div>
                    }
                        {
                            this.state.isLoading?
                            <div className="" style={{height:'400px',background:'#fff'}}>
                                <div className="spinner">
                                    <div style={style}>
                                        <ClipLoader
                                            size={50}
                                            color={"#000000"}
                                            loading={this.state.isLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        
                        {this.state.showPopUp ? (
                            <ModelPopup
                            openPopup={this.state.showPopUp}
                            closePopup={this.closingShowPopup}
                            message={this.state.message}
                            imagetype={this.state.imagetype}
                            />
                        ) : null}
                    </div>
                </div>
                </div>
                
                <div className="footer">
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <td width="30%">
                                    <button key={'Cancel'} className="btn btn-theme-dark w-auto" onClick={this.handleBack}>{i18next.t('Back')}</button>
                                </td>
                                <td width="40%">
                                    <button className="btn btn-theme-dark w-auto" onClick={this.Addcard}>{i18next.t('AddCard')}</button>                                    
                                </td>
                                <td width="30%">
                                    <button key={'pay'} className="btn btn-theme-dark w-auto" onClick={this.CompletependingPayment} disabled={this.state.cardID==""?true:false}>{i18next.t("Pay")}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <FooterNav />
            </div>
            <OtpPopUp 
                showModal={this.state.showPayUModal}
                handleVerify={this.handleVerifyOTP}
                value={this.state.PayUOTP}
                handleChangeOTP={this.handleChangeOTP}
                desc={`Enter Your OTP`}
                isDisable={this.state.isOtpButtonDisable}
            />
            <ModelPopup
                color="Red"
                openPopup={this.state.apiPopup}
                closePopup={this.closingApiPopup}
                message={i18next.t(this.state.popupmsg)}
                imagetype={`error`}
                    
            />
            <CardValidation 
                value={this.state.cvv} 
                onChange={(value)=>this.setState({cvv:value,invalidCVV:value.toString().length==3?false:true})} 
                showCardValidation={this.state.showCardValidation}
                onCancel={()=>this.setState({showCardValidation:false})}
                verifyCVV={this.payPendingAmount}
                text={this.state.paytmTransactionID?'Kindly re -enter CVV number of your saved card to complete payment':'Enter CVV number of your saved card to pre-authorization the payment for transaction and unlock the cooler door.'}
                />
          </>
        )
    }
}

export default PayBalance;
